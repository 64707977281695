import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding-top: 4rem;
  padding-bottom: 4rem;

  @media (min-width: 641px) {
    padding-top: 2rem;
  }

  @media (min-width: 768px) {
    padding-top: 4rem;
  }

  @media (min-width: 992px) {
    padding-top: 8rem;
  }
`;

const SkillList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: center;

  & li {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    width: 100%;
    max-width: 12rem;

    @media (min-width: 641px) {
      max-width: 18rem;
      padding: 1rem 5rem;
    }
  }
`;

const Skills = () => (
    <section className="container">
        <Wrapper>
            <div className="container-sm">
            <SkillList>
                <li>
                    <img src="images/skills/csharp.svg" alt="C Sharp" />
                </li>
                <li>
                    <img src="images/skills/javascript.svg" alt="Javascript" />
                </li>
                <li>
                    <img src="images/skills/react.svg" alt="React" />
                </li>
                <li>
                    <img src="images/skills/vue.svg" alt="Vue" />
                </li>
                <li>
                    <img src="images/skills/html.svg" alt="HTML 5" />
                </li>
                <li>
                    <img src="images/skills/css.svg" alt="CSS 3" />
                </li>
            </SkillList>
            </div>  
        </Wrapper>
    </section>
)

export default Skills;
