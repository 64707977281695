import React from "react"

import Master from "../layout/_master"
import HomeHero from '../components/home/homeHero'
import Skills from '../components/home/skills'
import SEO from "../components/seo"
import Testimonials from "../components/home/testimonials"

const IndexPage = () => (
  <Master>
    <SEO title="Home" />
    <HomeHero />
    <Skills />
    <Testimonials />
  </Master>
)

export default IndexPage
