import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'

import Shapes from '../images/shapes'

const Wrapper = styled.section`
    position: relative;
    text-align: center;
    padding-top: 4rem;
    max-width: 960px;

    @media (min-width: 768px) {
        text-align: left;
        padding-bottom: 9.2rem;
    }
`

const Content = styled.div`
    @media (min-width: 768px) {
        display: flex;
        flex-direction: row-reverse;
    }
`

const Images = styled.div`
    position: relative;
    padding-bottom: 12rem;

    @media (min-width: 641px) {
        padding-bottom: 0;
    }
`

const StyledImg = styled(Img)`
    width: 100%;
    max-width: 36rem;
    height: auto;
    margin: 0 auto;
    overflow: visible;
    transform: rotateY(-15deg);
    border: 2rem solid white;
    box-shadow: 0 0.8rem 2.4rem rgba(120, 13, 254, 0.12);
    right: 2.4rem;
    top: 3rem;

    @media (min-width: 641px) {
        max-width: none;
        width: 46rem;
        right: 3.2rem;
    }
    @media (min-width: 768px) {
        width: 56rem;
        right: 4.8rem;
    }
    @media (min-width: 881px) {
        width: 60rem;
    }
`

const HeroCopy = styled.div`    
    @media (min-width: 641px) {
        padding: 10rem 0 0;
    }
    @media (min-width: 768px) {
        padding: 0 2rem 0 0;
    }
`

const HomeHero = () => {
    const data = useStaticQuery(graphql`
        query {
            heroImage: file(relativePath: { eq: "trigger.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 300) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    return (
        <Wrapper>
            <div className="container">
                <Content>
                    <Images>
                        <Shapes />
                        <div style={{ perspective: '1000px' }}>
                            <StyledImg fluid={data.heroImage.childImageSharp.fluid} />
                        </div>
                    </Images>
                    <HeroCopy>
                        <h1>I google stuff for a living!</h1>
                        <p style={{ marginBottom: '3.2rem', fontSize: '2rem', lineHeight: '3.2rem' }}>
                            I'm a web developer from Phoenix Arizona. I'm a lifelong learner and I love exploring
                            new technologies and solving new problems.
                        </p>
                        <p>
                            <a className="button button-primary button-shadow" href="mailto:contact@kevintreynolds.com" target="_blank" rel="noopener noreferrer">
                                Contact Me
                            </a>
                        </p>
                    </HeroCopy>
                </Content>
            </div>
        </Wrapper>
    )
}

export default HomeHero
